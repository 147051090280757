<template>
  <window-content v-if="!loading">
    <div class="w-m-header">
      <h2 v-if="!leilao.id">Novo Leilão</h2>
      <div class="full-width" v-else>
        <span class="font-16">LEILÃO ID {{ leilao.id }} - {{ leilao.descricao }}</span>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body">
      <div class="bem-container">
        <!--          <div class="bem-menus">
                  </div>-->
        <div class="bem-page">
          <erp-tabs @change="changeTab" :active="tabActive">
            <erp-tab-item name="website">Principal</erp-tab-item>
            <erp-tab-item name="marketplace">Marketplace</erp-tab-item>
            <erp-tab-item name="definicoes">Definições</erp-tab-item>
            <erp-tab-item name="seguranca">Segurança</erp-tab-item>
            <erp-tab-item name="automacao">Automação</erp-tab-item>
            <erp-tab-item name="mais">Mais</erp-tab-item>
            <erp-tab-item name="info">Info</erp-tab-item>
          </erp-tabs>
          <layout-paper class="page-lg">
            <div v-show="tabActive === 'website'" class="wrapper m-t">
              <!-- -->
              <e-row mr>
                <e-col class="input-font-bold">
                  <erp-s-field
                      view="lr"
                      label="Descrição interna:"
                      label-width="90px"
                  >
                    <erp-input autofocus simple-border v-model="leilao.descricaoInterna"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row mr>

                <e-col style="max-width: 220px; min-width: 220px">
                  <erp-s-field
                      view="lr"
                      label="Código:"
                      label-width="90px"
                  >
                    <erp-input autofocus simple-border v-model="leilao.codigo" readonly/>
                  </erp-s-field>
                </e-col>

                <e-col style="min-width: 156px; max-width: 156px">
                  <tipo-leilao-select simple-border label="Tipo" field-view="lr"
                                      v-model="leilao.tipo"/>
                </e-col>

                <e-col style="max-width: 160px">
                  <erp-s-field
                      view="lr"
                      label="Judicial:"
                      label-width="40px"
                  >
                    <erp-select simple-border v-model="leilao.judicial"
                                :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                  </erp-s-field>
                </e-col>

                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Leilões:"
                      label-width="40px"
                  >
                    <erp-select simple-border v-model="leilao.instancia"
                                :options="[{label: 'Um leilão', value: 1}, {label: 'Dois leilões', value: 2}, {label: 'Três leilões', value: 3}]"/>
                  </erp-s-field>
                </e-col>
                <e-col v-if="leilao.instancia > 1" style="max-width: 120px">
                  <erp-s-field
                      view="lr"
                      label="Leilão atual:"
                  >
                    <erp-select v-model="leilao.praca"
                                :options="pracasPermitidas"/>
                  </erp-s-field>
                </e-col>

                <!--                  <e-col style="max-width: 160px">
                                    <erp-s-field
                                        view="lr"
                                        label="Pregão:"
                                        label-width="40px"
                                    >
                                      <erp-select simple-border v-model="leilao.inicioPregao"
                                                  :options="[{label: 'Na abertura', value: 1}, {label: 'No fechamento', value: 2}]"/>
                                    </erp-s-field>
                                  </e-col>-->

              </e-row>

              <e-row v-for="numeroLeilao in leilao.instancia" :key="'leilao-nro-' + numeroLeilao" mr>
                <e-col style="max-width: 220px; min-width: 220px">
                  <erp-s-field
                      view="lr"
                      :label="leilao.instancia === 1 ? 'Data do leilão:' : `Data leilão ${numeroLeilao}:`"
                      label-width="90px"
                  >
                    <datetime-input date-type="date" date-format="##/##/####" v-model.lazy="leilao['data' + numeroLeilao]"
                                    simpleBorder/>
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 130px; min-width: 130px">
                  <erp-s-field
                      view="lr"
                      label="Hora:"
                  >
                    <datetime-input :icon="false" date-type="time" date-format="##:##"
                                    v-model.lazy="extra['hora' + numeroLeilao]"
                                    simpleBorder/>
                  </erp-s-field>
                </e-col>

                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Abertura para lances:"
                      label-width="170px"
                      icon-help="Data e hora que o leilão poderá receber lances. Se a data de abertura for preenchida, toda seção de divulgação do leilão mostrará a data de abertura e data do leilão (encerramento). Se não preencher esta data, o sistema mostrará normalmente somente a data do leilão, e a abertura para lances fica facultativo do administrador do sistema alterar o status para 'Aberto para Lances'"
                  >
                    <datetime-input date-type="date" date-format="##/##/####" v-model.lazy="leilao['dataAbertura' + numeroLeilao]"
                                    simpleBorder/>
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 130px">
                  <erp-s-field
                      view="lr"
                      label="Hora:"
                  >
                    <datetime-input :icon="false" date-type="time" date-format="##:##"
                                    v-model.lazy="extra['horaAbertura' + numeroLeilao]"
                                    simpleBorder/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row mr>
                <e-col>
                  <default-async-select label-width="90px" field-view="lr" label="Classificação:" simple-border
                                        required
                                        v-model="leilao.classificacao" :list="listClassificacao" ref="classificacaoList" @change="changeClassificacao"/>
                </e-col>
                <e-col style="max-width: 260px">
                  <erp-s-field
                      view="lr"
                      label="Fuso horário:"
                  >
                    <erp-select simple-border v-model="leilao.timezone"
                                :options="timezones"/>
                  </erp-s-field>
                </e-col>

                <!--                  <e-col style="max-width: 160px">
                                    <erp-s-field
                                        view="lr"
                                        label="Venda direta:"
                                    >
                                      <erp-select simple-border v-model="leilao.vendaDireta"
                                                  :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                                    </erp-s-field>
                                  </e-col>-->

                <e-col v-if="leilao.instancia === 2" style="max-width: 170px">
                  <erp-s-field
                      view="lr"
                      label="Desconto 2o Leilão:"
                  >
                    <erp-input simple-border v-model="leilao.descontoSegundaPraca" type="number" suffix="%"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row mr>
                <e-col>
                  <default-async-select label-width="90px" simple-border field-view="lr" label="Leiloeiro:"
                                        v-model="leilao.leiloeiro" :list="listLeiloeiros"/>
                </e-col>
              </e-row>
              <!-- -->
              <e-row>
                <e-col>
                  <e-row class="m-t" mr>
                    <e-col style="max-width: 200px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          label="Habilitar robô:"
                          icon-help="O robô controla o leilão automaticamente."
                      >
                        <sl-checkbox v-model="leilao.controleAutomatico"/>
                      </erp-s-field>
                    </e-col>
                    <e-col style="max-width: 172px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          label="Habilitar cronômetro:"
                          tag="div"
                          :disabled="leilao.controleAutomatico"
                      >
                        <sl-checkbox :disabled="leilao.controleAutomatico" v-model="leilao.cronometro"/>
                      </erp-s-field>
                    </e-col>
                    <e-col style="min-width: 200px; max-width: 268px">
                      <erp-s-field
                          class="full-width m-t-n-xs"
                          view="lr"
                          label="Modo Tempo:"
                          label-width="90px"
                          icon-help="Renovar: sempre que o tempo estiver abaixo do tempo do cronômetro, será renovado. Incremental: sempre que o tempo estiver abaixo do cronômetro, será incrementado a mais no tempo atual."
                      >
                        <erp-select class="full-width" v-model="leilao.tipoCronometro"
                                    :options="[
                                          {label: 'Renovar', value: 0},
                                          {label: 'Incremental', value: 1}
                                      ]"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label="Cronômetro:"
                          label-width="90px"
                      >
                        <erp-select class="full-width" v-model="leilao.timerPregao"
                                    :options="opcoesCronometro"/>
                      </erp-s-field>
                    </e-col>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label="Encerrar primeiro lote:"
                          label-width="140px"
                          icon-help="Encerrar no horário faz com que o cronômetro inicie antes da data de realização do leilão para estar 0 no horário informado, desde que não receba lance. Começar após o horário faz com que o cronômetro do primeiro lote inicie exatamente no horário de encerramento do leilão e termine após o tempo definido do cronômetro. Funciona somente com o robô ativo."
                      >
                        <erp-select :disable="!leilao.controleAutomatico" :placeholder="!opcoesEncerramento || !opcoesEncerramento.length ? 'Preencha a data' : 'Selecione'" class="full-width" v-model="leilao.controleTempoInicial"
                                    :options="opcoesEncerramento"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label="Forma de Fechar:"
                          label-width="90px"
                      >
                        <erp-select :disable="!leilao.controleAutomatico" placeholder="Selecione" class="full-width" v-model="leilao.controleSimultaneo"
                                    :options="opcoesFechamentoLotes"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row v-if="leilao.controleSimultaneo" mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          :disabled="!leilao.controleAutomatico"
                          label="Intervalo entre lotes:"
                          icon-help="Tempo que o robô (em segundos) irá adicionar como intervalo para cada lote. Somente em caso de leilão simultâneo."
                      >
                        <erp-input :disable="!leilao.controleAutomatico" type="number" style="max-width: 60px"
                                   size="2" v-model="leilao.timerIntervalo"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row>
                    <e-col class="font-11">
                      <div style="margin-left: 102px; user-select: none">
                        <a v-if="!roboModoAvancado" @click="roboModoAvancado = true" class="text-blue-grey">Mudar para modo avançado de edição das informações de encerramento</a>
                        <a v-else @click="roboModoAvancado = false" class="text-negative">Voltar para modo simples de edição das informações de encerramento</a>
                      </div>
                    </e-col>
                  </e-row>
                  <e-row v-show="roboModoAvancado" class="m-t">
                    <e-col class="form-column-align-center">
                      <e-row class="m-t-n-xs" mr>
                        <e-col style="max-width: 250px">
                          <erp-s-field
                              view="lr"
                              label-width="90px"
                              label="Fechar Simultâneo:"
                              icon-help="Por padrão, recomendamos o fechamento lote a lote. Se habilitar esta função, o fechamento de todos os lotes será simultâneo, respeitando o intervalo em segundos definido no cronômetro. O primeiro lote terá um intervalo definido separadamente."
                          >
                            <sl-checkbox v-model="leilao.controleSimultaneo"/>
                          </erp-s-field>
                        </e-col>
                        <e-col>
                          <erp-s-field
                              view="lr"
                              label-width="90px"
                              label="Cronômetro sempre ativo:"
                              icon-help="O cronômetro no site, painel do arrematante e demais áreas de visualização dos lotes sempre ficará ativo. Até a data do leilão o cronômetro estará em contagem regressiva."
                          >
                            <sl-checkbox v-model="leilao.cronometroSempreAtivo"/>
                          </erp-s-field>
                        </e-col>
                      </e-row>
                    </e-col>
                  </e-row>
                  <e-row v-if="roboModoAvancado" class="m-t-n-xs" mr>
                    <e-col style="max-width: 233px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          label="Cronômetro:"
                          icon-help="Tempo do cronômetro regressivo e tempo de renovação do cronômetro sempre que o sistema receber um lance durante o pregão ativo"
                      >
                        <erp-input type="number" style="max-width: 60px" size="2" v-model="leilao.timerPregao"/>
                      </erp-s-field>
                    </e-col>
                    <e-col style="max-width: 233px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          label="Intervalo do Primeiro Lote:"
                      >
                        <erp-input type="number" style="max-width: 60px" size="2" v-model="leilao.controleTempoInicial"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t m-b" mr>
                    <e-col style="max-width: 200px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          label="Lances automáticos:"
                      >
                        <sl-checkbox v-model="leilao.permitirLanceAutomatico"/>
                      </erp-s-field>
                    </e-col>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          label="Lances antecipados:"
                          icon-help="Permite receber lances antes da data de abertura"
                      >
                        <sl-checkbox v-model="leilao.permitirLanceAntecipado"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <div class="form-column-align-center">
                    <e-row mr>
                      <e-col style="min-width: 200px">
                        <erp-s-field
                            class="full-width"
                            view="lr"
                            label="Tipo do Auditório:"
                            label-width="90px"
                        >
                          <erp-select class="full-width" v-model="leilao.auditorioPadrao"
                                      :options="modelosAuditorio"/>
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label="Permitir escolha do auditório:"
                            icon-help="Permite que clientes escolham o auditório de sua preferência."
                        >
                          <sl-checkbox v-model="leilao.permitirEscolhaAuditorio"/>
                        </erp-s-field>
                      </e-col>
                    </e-row>
                  </div>

                  <div class="form-column-align-center">
                    <e-row mr class="m-t">
                      <e-col style="min-width: 400px">
                        <erp-s-field
                            class="full-width"
                            view="lr"
                            label="Habilitação:"
                            label-width="90px"
                        >
                          <erp-select class="full-width" v-model="leilao.habilitacao"
                                      :options="habilitacaoOptions"/>
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label="Total de habilitados:"
                        >
                          {{ Number(leilao.habilitados) }}
                        </erp-s-field>
                      </e-col>
                    </e-row>
                    <e-row mr>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label="Parcelamento:"
                            label-width="90px"
                        >
                          <erp-select v-model="leilao.permitirParcelamento"
                                      :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label="Máximo de parcelas:"
                        >
                          <erp-input v-model="leilao.parcelamentoQtdParcelas" type="number"/>
                        </erp-s-field>
                      </e-col>
                      <e-col style="max-width: 400px">
                        <erp-s-field
                            view="lr"
                            label="Entrada mínima (%):"
                            icon-help="Deixe 0% para divisão em parcelas iguais"
                            helper-position="lb"
                        >
                          <erp-input v-model="leilao.parcelamentoMinimoEntrada" type="number" suffix="%"/>
                        </erp-s-field>
                      </e-col>
                    </e-row>
                    <e-row mr>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label="Permitir Sublote:"
                            label-width="90px"
                        >
                          <erp-select v-model="leilao.sublote"
                                      :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label="Hab. por Lote e Direito de Preferência:"
                        >
                          <erp-select v-model="leilao.direitoPreferencia"
                                      :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                        </erp-s-field>
                      </e-col>
                    </e-row>
                    <e-row mr>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label-width="90px"
                            label="Venda Direta:"
                            icon-help="Se ativo, os bens não vendidos aberto para lances poderão receber propostas."
                        >
                          <sl-checkbox color="purpple" v-model="leilao.vendaDireta"/>
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            :disable="!leilao.vendaDireta"
                            icon-help="Opcional. Somente caso queira definir uma data limite para recebimento de propostas"
                            label="Data Limite para Propostas"
                        >
                          <datetime-input :disable="!leilao.vendaDireta" date-type="date" date-format="##/##/####" v-model.lazy="leilao.dataLimitePropostas"
                                          simpleBorder />
                        </erp-s-field>
                      </e-col>
                    </e-row>
                    <e-row mr>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label-width="90px"
                            label="Evento Privado:"
                            icon-help="Se ativo, o evento somente será visualizado por habilitados."
                        >
                          <sl-checkbox color="red" v-model="leilao.extra.privado"/>
                        </erp-s-field>
                      </e-col>
                      <e-col>
                        <erp-s-field
                            view="lr"
                            label-width="155px"
                            label="Liberar Evento para Comitentes:"
                            icon-help="Se ativo, mesmo o evento sendo privado, licitantes com habilitação para comitentes do evento podem visualizar e enviar ofertas."
                        >
                          <sl-checkbox v-model="leilao.extra.privadoLiberarComitentes"/>
                        </erp-s-field>
                      </e-col>
                    </e-row>
                  </div>
                </e-col>
                <div class="col m-l-xxl" style="min-width: 216px; max-width: 216px">
                  <imagem label="Foto Principal" legend="De preferência 800x600px" ref="imagem" :image="leilao.image"
                          @update="updateImagem" @toDelete="deleteImagemEvent"/>
                  <div class="text-center font-11 m-t-sm font-thin">
                    Deixe a foto em branco para que o sistema monte uma animação com as logomarcas do(s) comitente(s)
                    participante(s).
                  </div>
                </div>
              </e-row>
            </div>

            <div v-show="tabActive === 'marketplace'" class="wrapper">
              <h2 class="diviser two-lines">
                Marketplace
                <span>Informações para divulgação nos sites</span>
              </h2>
              <e-row class="m-t-lg" mr>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Título:"
                      label-width="90px"
                      :helper="titleCountLabel"
                      helper-position="lb"
                  >
                    <erp-input v-model="leilao.titulo"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row class="m-t-sm font-thin font-12">
                <e-col>
                  <erp-s-field
                      view="lr"
                      label-width="90px"
                      label=""
                  >
                    O título é muito importante para um bom anúncio do leilão. Monte-o de forma resumida mas que
                    apresente o bem da melhor forma possível. Este título estará em destaque nos resultados das
                    pesquisas do Google, Redes Sociais e demais marketplaces integrados.
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row class="m-t-sm" mr>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Site(s):"
                      icon-help="Permite selecionar para quais sites nossa API irá divulgar o leilão"
                      label-width="90px"
                  >
                    <!--                  <erp-select multiple v-model="leilao.sites" placeholder="Todos" :options="sitesFiltered"
                                                  :loading="extra.loadingSites"></erp-select>-->
                    <multisite v-model="leilao.sites"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row mr>
                <e-col style="max-width: 220px">
                  <erp-s-field
                      view="lr"
                      icon-help="Torna este leilão um leilão em destaque no site. Observando que isto irá depender da funcionalidade de destaque desenvolvida no site."
                      label="Destaque:"
                      label-width="92px"
                  >
                    <sl-checkbox v-model="leilao.destaque"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <e-row mr>
                <div class="col">
                  <erp-s-field
                      view="lr"
                      label="Descrição:"
                      label-width="90px"
                  >
                  <textarea v-model="leilao.descricao" style="resize: vertical" rows="3"
                            class="sl-textarea"/>
                  </erp-s-field>
                </div>
              </e-row>

              <div class="row erp-row erp-row-col-md">
                <div class="col">
                  <erp-s-field
                      view="lr"
                      label="Informação de visitação:"
                      label-width="90px"
                      class="wrap label-wrap"
                  >
                  <textarea v-model="leilao.infoVisitacao" style="resize: vertical" rows="3"
                            class="sl-textarea"/>
                  </erp-s-field>
                </div>
              </div>

              <div class="row erp-row erp-row-col-md">
                <div class="col">
                  <erp-s-field
                      view="lr"
                      label="Informação de retirada:"
                      label-width="90px"
                      class="wrap label-wrap"
                  >
                  <textarea v-model="leilao.infoRetirada" style="resize: vertical" rows="3"
                            class="sl-textarea"/>
                  </erp-s-field>
                </div>
              </div>

              <div class="row erp-row erp-row-col-md">
                <div class="col">
                  <erp-s-field
                      view="lr"
                      label="Observações:"
                      label-width="90px"
                      class="wrap label-wrap"
                  >
                  <textarea v-model="leilao.observacao" style="resize: vertical" rows="3"
                            class="sl-textarea"/>
                  </erp-s-field>
                </div>
              </div>

              <div class="row erp-row erp-row-col-md">
                <div class="col">
                  <erp-s-field
                      view="lr"
                      label="Informação de pagamento:"
                      label-width="90px"
                      class="wrap label-wrap"
                  >
                  <textarea v-model="leilao.infoPagamento" style="resize: vertical" rows="3"
                            class="sl-textarea"/>
                  </erp-s-field>
                </div>
              </div>

              <e-row class="m-t-sm" mr>
                <e-col>
                  <erp-s-field
                      view="lr"
                      label="Leilão divulgação:"
                      icon-help="Se preenchido, o leilão somente será divulgado no site mas ao clicar será redirecionado para a URL informada"
                      label-width="90px"
                  >
                    <erp-input size="2"  v-model="leilao.extra.urlExterna" placeholder="https://www..." />
                  </erp-s-field>
                </e-col>
                <e-col style="max-width: 300px">
                  <erp-s-field
                      view="lr"
                      label="Plataforma:"
                      label-width="70px"
                  >
                    <erp-select :disable="!leilao.extra.urlExterna || !leilao.extra.urlExterna.length" v-model="leilao.extra.urlExternaEmpresa"
                                :options="[{label: 'Comprei', value: 'comprei'}, {label: 'Outras', value: 'outras'}]"/>
                  </erp-s-field>
                </e-col>
              </e-row>

              <div class="row erp-row def-m-r">
                <div class="col" style="min-width: 200px">
                  <erp-s-field
                      view="lr"
                      label-width="90px"
                      style="line-height: 20px"
                      tag="div"
                  >
                    <erp-checkbox class="flex items-center" v-model="leilao.publicarSite">
                      <label class="no-select font-bold">
                        <span class="m-l-xs">Publicar no site</span>
                      </label>
                    </erp-checkbox>
                  </erp-s-field>
                </div>

                <div class="col" style="max-width: 200px">
                  <status-select label="Quando:" field-view="lr" v-model="leilao.publicarSiteQuando"/>
                </div>

                <div class="col" style="min-width: 200px">
                  <erp-s-field
                      view="lr"
                      label="A partir de:"
                  >
                    <datetime-input date-format="##/##/#### ##:##" v-model.lazy="leilao.dataPublicacao"
                                    simpleBorder/>
                  </erp-s-field>
                </div>
              </div>
            </div>

            <div v-if="tabActive === 'definicoes'" class="wrapper m-t">
              <leilao-impostos :leilao="leilao" />
              <comitente-multiple-select v-model="leilao.comitentesSelecionados" class="m-t-lg" />
              <leilao-local :leilao="leilao" :extra="extra" @processPatioData="processPatioData" />
            </div>

            <div v-if="tabActive === 'seguranca'" class="wrapper m-t">
              <e-row mr class="m-t">
                <e-col>
                  <erp-s-field
                      class="full-width"
                      view="tl"
                      label="Forma de Habilitação:"
                  >
                    <erp-select class="full-width" v-model="leilao.habilitacao"
                                :options="habilitacaoOptions"/>
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field
                      class="full-width"
                      view="tl"
                      label="Exigir biometria facial ao entrar no auditório:"
                  >
                    <erp-select class="full-width" v-model="fakeNull"
                                :options="[{label: 'Não', value: null}, {label: 'Sim', value: 0, disable: true}]"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row mr>
                <e-col>
                  <erp-s-field
                      class="full-width"
                      view="tl"
                      label="Habilitar vídeo-conferência entre licitantes habilitados e leiloeiro no auditório:"
                      icon-help="Se habilitado, o leiloeiro pode requisitar chamada de vídeo com um, mais, ou todos os participantes do auditório do leilão. Esta chamada ficará gravada e pública no auditório. Também é possível chamada individual para validação biométrica e por voz somente entre o leiloeiro, pelo ERP, com o licitente, direto do seu auditório. A chamada ficará gravada."
                  >
                    <erp-select class="full-width" v-model="fakeNull"
                                :options="[{label: 'Desativado', value: null}, {label: 'Sim', value: 0, disable: true}]"/>
                  </erp-s-field>
                </e-col>
              </e-row>
              <e-row mr>
                <e-col>
                  <erp-s-field
                      class="full-width"
                      view="tl"
                      label="Evento Privado:"
                  >
                    <erp-select class="full-width" v-model="leilao.nivelAcesso"
                                :options="[
                                {label: 'Não', value: undefined},
                                {label: 'Somente usuários logados e aprovados', value: 1, disable: true},
                                {label: 'Somente usuários habilitados no evento', value: 2, disable: true},
                                ]"/>
                  </erp-s-field>
                </e-col>
                <e-col>
                  <erp-s-field
                      class="full-width"
                      view="tl"
                      label="Permitir chat:"
                  >
                    <erp-select class="full-width" v-model="leilao.extra.enableChat"
                                :options="[
                                {label: 'Não', value: undefined},
                                {label: 'Somente entre usuários e leiloeiro', value: 1, disable: true},
                                {label: 'Chat público ao auditório', value: 2, disable: true},
                                ]"/>
                  </erp-s-field>
                </e-col>
              </e-row>
            </div>

            <div v-if="tabActive === 'mais'" class="wrapper m-t">
              <e-row class="wrapper-lg">
                <e-col>

                  <e-row class="m-b">
                    <e-col style="max-width: 400px">
                      <strong>Eventos de aviso para arrematantes:</strong>
                    </e-col>
                  </e-row>
                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Início do leilão:"
                          label-width="140px"
                      >
                        <sl-checkbox v-model="leilao.extra.avisarInicioLeilao"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Lance superado:"
                          label-width="140px"
                      >
                        <sl-checkbox v-model="leilao.extra.avisoLanceSuperado"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Lance superado durante leilão:"
                          label-width="140px"
                      >
                        <div class="flex items-center">
                          <sl-checkbox v-model="leilao.extra.avisoLanceSuperadoLeilao"/>
                          <span class="m-l font-12 text-negative">* Altamente não recomendável</span>
                        </div>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Arrematação:"
                          label-width="140px"
                      >
                        <sl-checkbox v-model="leilao.extra.avisoArrematacao"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Lance ganhador:"
                          label-width="140px"
                      >
                        <sl-checkbox v-model="leilao.extra.avisoLanceGanhador"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Encerramento do lote:"
                          label-width="140px"
                      >
                        <sl-checkbox v-model="leilao.extra.avisoEncerramentoLote"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Aviso de lote Cancelado/Retirado:"
                          label-width="140px"
                      >
                        <sl-checkbox v-model="leilao.extra.avisoCancelamentoLote"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <hr class="m-t m-b-lg" />

                  <!-- TODO: Indices para parcelas -->

                  <e-row>
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Permitir propostas:"
                          label-width="140px"
                      >
                        <erp-select v-model="leilao.permitirPropostas"
                                    :options="[{label: 'Não', value: false}, {label: 'Sim', value: true}]"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <!-- TODO: Texto propostas -->

                  <e-row class="m-t">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Vídeo/Trasmissão:"
                          label-width="140px"
                      >
                        <erp-input v-model="leilao.video"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Leilão atual:"
                          label-width="140px"
                      >
                        <erp-select v-model="leilao.praca"
                                    :options="pracasPermitidas"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Auditório sempre aberto:"
                          label-width="140px"
                      >
                        <erp-select v-model="leilao.auditorioSempreAberto"
                                    :options="[{label: 'Sim', value: true}, {label: 'Não', value: false}]"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Auditório público:"
                          label-width="140px"
                      >
                        <erp-select v-model="leilao.auditorioPublico"
                                    :options="[{label: 'Sim', value: true}, {label: 'Não', value: false}]"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Nomeclatura:"
                          label-width="140px"
                      >
                        <erp-input v-model="leilao.nomeclatura"/>
                      </erp-s-field>
                    </e-col>
                    <e-col class="flex" style="align-items: center">
                      <div class="m-l">Exemplos: Leilão/Praça/Instância</div>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="Integrar com outros leiloeiros:"
                          label-width="140px"
                      >
                        <erp-select v-model="leilao.multileiloeiro"
                                    :options="[{label: 'Sim', value: true}, {label: 'Não', value: false}]"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label="MultiLeiloeiro Configuração:"
                          label-width="140px"
                      >
                        <erp-input v-model="leilao.multileiloeiroConfig"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="max-width: 400px;">
                      <erp-s-field
                          view="lr"
                          label="Terceira data:"
                          label-width="140px"
                      >
                        <datetime-input date-type="datetime" date-format="##/##/#### ##:##"
                                        v-model.lazy="leilao.extra.terceiraData"
                                        simpleBorder/>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t">
                    <e-col style="width: 100%">
                      <div class="m-b">Regras <a class="font-10 m-l" @click="loadRegrasDefault">Carregar regras
                        padrão</a>
                      </div>
                      <u-editor class="full-width" v-model="leilao.regras"/>
                    </e-col>
                  </e-row>

                </e-col>
                <e-col>
                  <e-row mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.doulheRenovarCronometro">
                          <label class="no-select">
                            <span class="m-l-xs">Dou-lhe Uma e Dou-lhe Duas renovar cronômetro</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.homologarAutomaticamente">
                          <label class="no-select">
                            <span class="m-l-xs">Auto Homologar quando zerar o cronômetro</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t-lg">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Desativa webhooks com o site e outras ações para priorizar a performance sistêmica durante o leilão"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.priorizarPerformance">
                          <label class="no-select">
                            <span class="m-l-xs text-negative">Priorizar Performance*</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>

                  <e-row class="m-t-lg" mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                      >
                        <e-row>
                          <e-col style="max-width: 12px; ">
                            <erp-checkbox class="flex items-center" v-model="leilao.extra.permitirDisputaLancesAvistaParcelado">
                            </erp-checkbox>
                          </e-col>
                          <e-col><label class="no-select">
                            <span class="m-l-xs" style="white-space: break-spaces">Permitir disputa de lances à vista com lances parcelado</span>
                          </label></e-col>
                        </e-row>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                      >
                        <e-row>
                          <e-col style="max-width: 12px; ">
                            <erp-checkbox class="flex items-center" v-model="leilao.extra.permitirDisputaLancesParcelados">
                            </erp-checkbox>
                          </e-col>
                          <e-col><label class="no-select">
                            <span class="m-l-xs" style="white-space: break-spaces">Permitir disputa de lances parcelados priorizando menor parcela</span>
                          </label></e-col>
                        </e-row>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                      >
                        <e-row>
                          <e-col style="max-width: 12px; ">
                            <erp-checkbox class="flex items-center" v-model="leilao.extra.limitarParcela">
                            </erp-checkbox>
                          </e-col>
                          <e-col><label class="no-select">
                            <span class="m-l-xs" style="white-space: break-spaces">Definir limite mínimo de parcela no lance parcelado</span>
                          </label></e-col>
                        </e-row>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="m-t-n" mr>
                    <e-col>
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                      >
                        <e-row>
                          <e-col>
                            <erp-s-field
                                view="lr"
                                label="Parcela Mínima:"
                                icon-help="Valo mínimo da parcela, se a limitação for ativada"
                            >
                            <erp-input :disable="!leilao.extra.limitarParcela" v-model.lazy="leilao.extra.valorMinimoParcela" v-money="money" />
                            </erp-s-field>
                          </e-col>
                        </e-row>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="O envio das cobranças se torna individual, uma cobrança para cada lote"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.cobrarLotesIndividualmente">
                          <label class="no-select">
                            <span class="m-l-xs">Cobrar Lotes Individualmente</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Lotes que estiverem relacionados ao mesmo bem, o primeiro que receber lance, automaticamente prejudica os demais"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.prejudicarLotesRelacionados">
                          <label class="no-select">
                            <span class="m-l-xs">Prejudicar lotes relacionados</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Se habilitado, o robô não define os lotes como Não Vendido, mas sim como Repasse"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.roboRepasse">
                          <label class="no-select">
                            <span class="m-l-xs">Permitir Repasse com o Robô</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Se habilitado, o sistema não precisa de um valor inicial predefinido, caso não tenha, aceita qualquer lance inicial digitado pelo arrematante"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.lanceLivre">
                          <label class="no-select">
                            <span class="m-l-xs">Permitir Lance Livre Sem Valor Inicial</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Se habilitado, o sistema ignora a regra de aceitar lances somente múltiplos do incremento. Não recomendamos ativar"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.ignoraMultiplo">
                          <label class="no-select">
                            <span class="m-l-xs">Ignorar Regra de Múltiplo do Incremento</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Se habilitado, o sistema somente aceita lances do incremento. Não recomendamos ativar"
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.forcarIncremento">
                          <label class="no-select">
                            <span class="m-l-xs">Proibir lance maior que o incremento</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Se habilitado, o sistema somente aceita propostas na modalidade venda direta."
                      >
                        <!--<input type="checkbox" v-model="leilao.cronometro" id="cronometro">-->
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.proibirLanceVendaDireta">
                          <label class="no-select">
                            <span class="m-l-xs">Proibir lance modalidade venda direta</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Se habilitado, o sistema somente aceita lances em lotes cujo licitante se habilitou individualmente no lote e a habilitação esteja aprovada."
                      >
                        <erp-checkbox @click.native="obrigarHabilitacaoLote" class="flex items-center" v-model="leilao.extra.obrigarHabilitacaoLote">
                          <label class="no-select">
                            <span class="m-l-xs">Obrigar habilitação individual em lote para lances</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row class="">
                    <e-col style="max-width: 400px">
                      <erp-s-field
                          view="lr"
                          label-width="90px"
                          tag="div"
                          icon-help="Se habilitado, as vendas automáticas serão condicionais."
                      >
                        <erp-checkbox class="flex items-center" v-model="leilao.extra.vendaCondicional">
                          <label class="no-select">
                            <span class="m-l-xs">Fechar as vendas com status condicional</span>
                          </label>
                        </erp-checkbox>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                </e-col>
              </e-row>
              <hr class="m-t m-b-lg" />
              <div class="m-b font-16">Configurações especiais para Venda Direta</div>
              <e-row mr>
                <e-col>
                  <e-row mr>
                    <e-col>
                      <erp-s-field
                          label="Permitir Compre Já:"
                      >
                        <sl-checkbox v-model="leilao.extra.compreJa"/>
                        <p class="font-10 text-negative m-t-xs"><strong>Cuidado</strong>: Habilitando esta funcionalidade, ao receber uma oferta que atinja o valor mínimo a venda é finalizada imediatamente</p>
                      </erp-s-field>
                    </e-col>
                    <e-col>
                      <erp-s-field
                          label="Data Limite para Compre Já:"
                      >
                        <datetime-input date-type="datetime" date-format="##/##/#### ##:##" :disable="!leilao.extra.compreJa"
                                        v-model.lazy="leilao.extra.compreJaDataLimite"
                                        simpleBorder/>
                      </erp-s-field>
                      <p class="font-10 m-t-xs">O compre já irá funcionar até esta data, após ela, a função é desativada. Recomendamos o compre já até um dia antes do encerramento do evento.</p>
                    </e-col>
                  </e-row>
                </e-col>
              </e-row>
            </div>
            <automacao v-if="tabActive === 'automacao'" :leilao="leilao" class="wrapper m-t" />
            <leilao-info v-if="leilao && leilao.id && tabActive === 'info'" :leilao="leilao" class="wrapper m-t" />
          </layout-paper>
        </div>
      </div>
    </div>

    <div class="w-m-footer text-right footer-modern window-actions">
      <!--      <div v-if="!leilao.id" class="flex items-center justify-center font-11">
              <div class="m-l-xs">
                Ao salvar o leilão ele ficará com status <u>Rascunho</u>. Após validar as informações, altere o
                status para <u>Em breve</u>.
              </div>
            </div>-->
      <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
             @click="$uloc.window.close($root.wid)"/>
      <e-btn :disable="loading" label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
             @click="save"
             v-shortkey="['f2']" @shortkey.native="save"/>
    </div>
  </window-content>
  <window-loading :errors="errors" :status="status" v-else/>
</template>

<script>
import {
  WindowContent,
  // WindowHeader,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  // HelperInputBtn,
  // ErpBox,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import {debounce, UEditor} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find,
  newLeilao,
  updateLeilao,
  updateFotoLeilao,
  deleteFotoLeilao,
  listClassificacao,
  listLeiloeiros
} from '../../../../domain/leiloes/services/index'
import TipoLeilaoSelect from '../include/TipoLeilaoSelect'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../../../leiloes/components/include/StatusLeilaoSelect'
import ComitenteMultipleSelect from '../include/ComitenteMultipleSelect'
import {datePtToEn, datetimeToEn, convertDate} from '@/utils/date'
import Imagem from '../../../../reuse/upload/Imagem'
import ECol from '../../../layout/components/Col'
import ERow from '../../../layout/components/Row'
import {buscaCep} from '@/domain/utils/cep'
import timezones from '../../../../domain/utils/timezones'
import {findByCodigo} from '@/domain/templates/services'
import WindowLoading from '@/components/layout/window/Loading'
import Multisite from "@/components/website/components/Multisite"
import LayoutPaper from "@/components/layout/LayoutPaper"
import SlCheckbox from "@/reuse/input/Checkbox"
import Automacao from "@/components/leiloes/components/window/abas/Automacao"
import habilitacaoType from "@/domain/leiloes/helpers/habilitacaoType"
import LeilaoInfo from "components/leiloes/components/window/abas/Info.vue";
import LeilaoImpostos from "components/leiloes/components/include/Impostos.vue";
import LeilaoLocal from "components/leiloes/components/include/Local.vue";
const { addSeconds, parse, format } = require('date-fns')

let mockLeilao = {
  id: null,
  codigo: null,
  tipo: null,
  judicial: false,
  instancia: 1,
  inicioPregao: 1,
  descontoSegundaPraca: null,
  data1: null,
  data2: null,
  data3: null,
  dataAbertura1: null,
  dataAbertura2: null,
  dataAbertura3: null,
  dataAbertura: null, // @deprecated
  dataFimPraca1: null, // @deprecated
  dataAberturaPraca2: null, // @deprecated
  dataFimPraca2: null, // @deprecated
  dataPublicacao: null,
  classificacao: null,
  descricaoInterna: null,
  leiloeiro: null,
  destaque: false,
  cronometro: true,
  cronometroSempreAtivo: false,
  timerPregao: 180,
  permitirLanceAutomatico: true,
  permitirLanceAntecipado: true,
  controleAutomatico: false,
  controleSimultaneo: false,
  controleTempoInicial: 0,
  tipoCronometro: 0,
  auditorioPadrao: 'default',
  permitirEscolhaAuditorio: false,
  titulo: null,
  descricao: null,
  infoVisitacao: null,
  infoRetirada: null,
  infoPagamento: null,
  observacao: null,
  publicarSite: true,
  publicarSiteQuando: null,

  comitentesSelecionados: [],

  patio: null,

  sistemaTaxa: null,
  // taxas: null,
  extra: {
    doulheRenovarCronometro: false,
    homologarAutomaticamente: false,
    urlExterna: null,
    urlExternaEmpresa: null,
    proibirLanceVendaDireta: true
  },

  timerIntervalo: 60,

  cep: null,
  endereco: null,
  uf: null,
  cidade: null,
  enderecoNumero: null,
  bairro: null,
  enderecoReferencia: null,
  timezone: 'America/Sao_Paulo',
  vendaDireta: false,
  dataLimitePropostas: null,
  habilitacao: 1,
  habilitados: 0,
  permitirParcelamento: false,
  parcelamentoQtdParcelas: 12,
  parcelamentoMinimoEntrada: 0,
  parcelamentoIndices: null,
  permitirPropostas: false,
  video: null,
  praca: 1,
  regras: '',
  textoPropostas: null,
  auditorioSempreAberto: true,
  auditorioPublico: false,
  sites: [],
  nomeclatura: 'Leilão',
  multileiloeiro: false,
  multileiloeiroConfig: null,
  config: {
    templateCobranca: null,
    templateNota: null,
    templateRecibo: null,
    templateHabilitacao: null,
    templates: {}
  },
  direitoPreferencia: false,
  sublote: false
}

export default {
  name: 'LeilaoWindow',
  directives: {money: VMoney},
  props: ['id', 'tipo'],
  data() {
    return {
      money: REAL_BRL,
      tabActive: 'website',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      leilao: JSON.parse(JSON.stringify(mockLeilao)),
      fakeNull: null,
      habilitacaoOptions: habilitacaoType,
      extra: {
        hora1: null,
        hora2: null,
        hora3: null,
        horaAbertura1: null,
        horaAbertura2: null,
        horaAbertura3: null,
        fechamento: false, // @deprecated
        horaAbertura: null, // @deprecated
        horaFimPraca1: null, // @deprecated
        horaAberturaPraca2: null, // @deprecated
        horaFimPraca2: null, // @deprecated
        horaPublicacao: null, // @deprecated
        patio: {
          id: null,
          nome: null,
          sigla: null,
          uf: null,
          cep: null,
          cidade: null,
          endereco: null,
          numero: null,
          bairro: null,
          lat: null,
          lng: null
        },
        loadingSites: false,
        digitarEndereco: false
      },
      listClassificacao: listClassificacao,
      listLeiloeiros: listLeiloeiros,
      roboModoAvancado: false
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.loadRegrasDefault()
    }
  },
  computed: {
    timezones() {
      return timezones
    },
    sitesFiltered() {
      let sites = []
      return sites
    },
    pracasPermitidas() {
      const pracas = []
      for (let i = 1; i <= this.leilao.instancia; i++) {
        pracas.push({label: i, value: i})
      }
      return pracas
    },
    titleCount() {
      return this.leilao.titulo ? Number(String(this.leilao.titulo).length) : 0
    },
    titleCountLabel() {
      let count = this.titleCount
      return `${count} de 200 caracteres permitidos`
    },
    modelosAuditorio () {
      const list = []
      // if (!this.leilao.controleSimultaneo) {
      list.push({label: 'Lote a Lote v1', value: 'default'})
      list.push({label: 'Lote a Lote TRT', value: 'lote-lote-trt'})
      // list.push({label: 'Lote a Lote vBlack', value: 'black'})
      // }
      list.push({label: 'Cards Lado a Lado v1', value: 'cards-v1'})
      list.push({label: 'Cards Lado a Lado v1 Simples', value: 'cards-v1-simplificado'})
      // list.push({label: 'Cards Lado a Lado v2', value: 'cards-v2'})
      list.push({label: 'Justiça Federal', value: 'justica-federal'})
      list.push({label: 'Live', value: 'live'})
      return list
    },
    opcoesCronometro () {
      return [
        {label: '10 segundos', value: 10},
        {label: '15 segundos', value: 15},
        {label: '30 segundos', value: 30},
        {label: '45 segundos', value: 45},
        {label: '1 minuto', value: 60},
        {label: '2 minutos', value: 120},
        {label: '3 minutos', value: 180},
      ]
    },
    opcoesEncerramento () {
      try {
        const cronometro = this.leilao.timerPregao
        const praca = Number(this.leilao.praca)
        const datas = []
        datas[1] = this.extra.hora1
        datas[2] = this.extra.hora2
        datas[3] = this.extra.hora3
        if (typeof datas[praca] !== 'undefined' && datas[praca]) {
          const hora = datas[praca]
          const horarioExato = parse(hora, 'HH:mm', new Date())
          const horarioFinal = addSeconds(parse(hora, 'HH:mm', new Date()), cronometro)
          return [
            {label: format(horarioExato, 'HH:mm:ss'), value: 0},
            {label: format(horarioFinal, 'HH:mm:ss'), value: cronometro},
          ]
        }
        return []
      } catch (e) {
        return []
      }
    },
    opcoesFechamentoLotes () {
      return [
        {label: 'Lote a lote, iniciar o próximo somente após encerramento do anterior', value: false},
        {label: 'Simultâneo, acrescentando um intervalo de tempo entre cada lote', value: true}
      ]
    },
  },
  watch: {
    'leilao.dataFimPraca1'(v) {
      if (v) {
        this.extra.fechamento = true
      } else {
        this.extra.fechamento = false
      }
    },
    'leilao.regras'(v) {
      if (v === null) {
        this.leilao.regras = ''
      }
    },
    'leilao.controleAutomatico' (v) {
      if (v) {
        this.leilao.cronometro = true
        if (!this.leilao.id) {
          //this.leilao.auditorioPadrao = 'cards-v1'
        }
      }
    },
    'leilao.controleSimultaneo' (v) {
      if (v) {
        this.leilao.cronometroSempreAtivo = true
        //this.leilao.auditorioPadrao = 'cards-v1'
      } else {
        this.leilao.cronometroSempreAtivo = false
      }
    },
    'leilao.vendaDireta' (v) {
      if (v) {
        // this.leilao.instancia = 1
      }
    }
  },
  methods: {
    load() {
      find(this.id || this.leilao.id)
          .then(response => {
                let data = JSON.parse(JSON.stringify(response.data))

                data.codigo = data.numero + '/' + data.ano

                const datas = ['1', '2', '3']
                datas.map(numeroLeilao => {
                  let date = 'data' + numeroLeilao
                  let dateAbertura = 'dataAbertura' + numeroLeilao
                  if (typeof data[date] === 'undefined' || !data[date] || !data[date].date) return
                  console.log('XXX', data[date])
                  let dtConverted = convertDate(data[date], true)
                  console.log('YYY', dtConverted)
                  data[date] = dtConverted[0]
                  this.extra['hora' + numeroLeilao] = dtConverted[1]

                  if (typeof data[dateAbertura] === 'undefined' || !data[dateAbertura] || !data[dateAbertura].date) return
                  let dtAberturaConverted = convertDate(data[dateAbertura], true)
                  data[dateAbertura] = dtAberturaConverted[0]
                  this.extra['horaAbertura' + numeroLeilao] = dtAberturaConverted[1]
                })

                /*const onlyConfigIds = ['templateCobranca']
                onlyConfigIds.map(id => {
                  if (typeof data.config[id] !== 'undefined') {
                    data.config[id] = data.config[id].id ?? data.config[id]
                  }
                })*/

                data.dataPublicacao = convertDate(data.dataPublicacao)

                if (data.dataLimitePropostas) {
                  data.dataLimitePropostas = convertDate(data.dataLimitePropostas)
                }

                if (data.leiloeiro && data.leiloeiro.id) {
                  data.leiloeiro = data.leiloeiro.id
                }

                if (data.classificacao && data.classificacao.id) {
                  data.classificacao = data.classificacao.id
                }

                if (data.sistemaTaxa && data.sistemaTaxa.id) {
                  data.sistemaTaxaObject = data.sistemaTaxa
                  data.sistemaTaxa = data.sistemaTaxa.id
                }

                if (data.comitentes) {
                  data.comitentesSelecionados = data.comitentes
                }

                if (data.patio) {
                  this.extra.patio = JSON.parse(JSON.stringify(data.patio))
                  data.patio = data.patio.id
                }

                if (!data.extra || Array.isArray(data.extra)) {
                  data.extra = JSON.parse(JSON.stringify(mockLeilao.extra))
                }

                if (data.extra.terceiraData) {
                  data.extra.terceiraData = convertDate({date: data.extra.terceiraData}, true)
                  data.extra.terceiraData = data.extra.terceiraData.join(' ')
                }

                if (null === data.multileiloeiro) {
                  data.multileiloeiro = false
                }

                if (data.sites) {
                  data.sites = data.sites.map(s => s.id)
                } else {
                  data.sites = []
                }

                /*if (response.data.tipo !== null) {
                  this.$nextTick(() => {

                  })
                }*/
                let mock = JSON.parse(JSON.stringify(mockLeilao))
                this.leilao = Object.assign(mock, data)
                if (!this.leilao.config) {
                  this.leilao.config = JSON.parse(JSON.stringify(mockLeilao.config))
                }
                if (!this.leilao.config.templates || Array.isArray(this.leilao.config.templates)) {
                  this.leilao.config.templates = {}
                }
                this.$nextTick(() => {
                  this.loading = false
                })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar o leilão, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    changeTab({tab}) {
      this.tabActive = tab
    },
    processPatioData(data) {
      this.extra.patio = {...data}
      this.extra.patio.lat = data.latitude
      this.extra.patio.lng = data.longitude
    },
    save() {
      let data
      try {
        console.log('Save')
        data = JSON.parse(JSON.stringify(this.leilao))

        const datas = [1, 2, 3]
        datas.map(numeroLeilao => {
          if (Number(data.instancia) < numeroLeilao) {
            data['data' + numeroLeilao] = null
            data['dataAbertura' + numeroLeilao] = null
            this.leilao['data' + numeroLeilao] = null
            this.leilao['dataAbertura' + numeroLeilao] = null
            this.extra['hora' + numeroLeilao] = null
            this.extra['horaAbertura' + numeroLeilao] = null
            return
          }
          if (data['data' + numeroLeilao]) {
            data['data' + numeroLeilao] = datetimeToEn(`${data['data' + numeroLeilao]} ${this.extra['hora' + numeroLeilao]}`)
          }
          if (data['dataAbertura' + numeroLeilao]) {
            data['dataAbertura' + numeroLeilao] = datetimeToEn(`${data['dataAbertura' + numeroLeilao]} ${this.extra['horaAbertura' + numeroLeilao]}`)
          }
        })

        if (data.dataPublicacao) {
          data.dataPublicacao = datetimeToEn(`${data.dataPublicacao} ${this.extra.horaPublicacao}`)
        }

        if (data.dataLimitePropostas) {
          data.dataLimitePropostas = datetimeToEn(`${data.dataLimitePropostas} 23:59:59`)
        }

        if (data.extra.terceiraData) {
          data.extra.terceiraData = datetimeToEn(data.extra.terceiraData)
        }
        if (data.config && data.config.templates) {
          data.config.templates = JSON.stringify(data.config.templates)
        }
        if (data.config && data.config.extra) {
          data.config.extra = JSON.stringify(data.config.extra)
        }

        if (data.comitentesSelecionados && data.comitentesSelecionados.length > 0) {
          data.comitentes = data.comitentesSelecionados.slice().map(c => {
            return {
              id: c.id,
              apelido: c.apelido,
              pessoa: {
                id: c.pessoa.id,
                name: c.pessoa.name
              },
              image: c.image
            }
          })
        } else {
          data.comitentes = []
        }
        data.comitentes = JSON.stringify(data.comitentes)

        if (data.sistemaTaxa && data.sistemaTaxa.id) {
          data.sistemaTaxa = data.sistemaTaxa.id
        }

        data.extra = JSON.stringify(data.extra)

        if (!data.parcelamentoMinimoEntrada) {
          data.parcelamentoMinimoEntrada = 0
        }

        const onlyConfigIds = ['templateCobranca', 'templateNota', 'templateRecibo', 'templateHabilitacao']
        onlyConfigIds.map(id => {
          if (typeof data.config[id] !== 'undefined' && data.config[id]) {
            data.config[id] = data.config[id].id ?? data.config[id]
          }
        })
      } catch (e) {
        this.alertApiError(null, 'Erro de validação', null, e.message)
        return
      }
      this.loading = true
      let method = data.id ? updateLeilao(data.id, data) : newLeilao(data)
      method
          .then(({data}) => {
            let novo = true
            if (this.leilao.id) {
              novo = false
            }
            this.leilao.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.loading = false
              this.dg()
              this.$nextTick(() => {
                this.load()
                this.$uloc.window.emit(this.$root.wid, 'update', this.leilao)
                novo && this.$uloc.window.emit(this.$root.wid, 'new', this.leilao)
              })
            }
            // Processa foto
            if (this.deleteFoto) {
              console.log('Deleta Foto')
              this.$imagem && delete this.$imagem
              cbSuccess()
            } else if (this.$imagem) {
              this.status = 'Analisando e processando arquivos do leilão'
              updateFotoLeilao(this.leilao.id, {data: this.$imagem, filename: 'foto'})
                  .then(({data}) => {
                    cbSuccess()
                    delete this.$imagem
                  })
                  .catch((error) => {
                    alert('Impossível enviar a imagem principal do leilão, mas os demais dados foram processados com sucesso!')
                    console.error(error)
                    cbSuccess()
                  })
            } else {
              cbSuccess()
            }
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    deleteImagemEvent() {
      this.deleteImagem = true
      deleteFotoLeilao(this.leilao.id)
          .then((response) => {
            this.leilao.image = null
            console.log(response.data)
          })
          .catch(error => {
            console.log('Não foi possível remover a foto do leilão')
            console.error(error)
          })
    },
    updateImagem(file) {
      this.$imagem = file
    },
    loadRegrasDefault() {
      findByCodigo('regras')
          .then((response) => {
            this.leilao.regras = response.data.texto
          })
          .catch(error => {
            console.log('Não foi possível carregar o template padrão')
            console.error(error)
          })
    },
    changeClassificacao () {
      this.$nextTick(() => {
        if (this.leilao.classificacao) {
          const item = this.$refs.classificacaoList.asyncdata.find(o => o.id === this.leilao.classificacao)
          if (item && item.config) {
            if (item.config.leilao) {
              console.log('Alterando configurações do leilão', item.config.leilao)
              this.leilao = Object.assign(this.leilao, item.config.leilao)
            }
            if (item.config.templates) {
              console.log('Alterando configurações do template', item.config.templates)
              this.leilao.config.templates = item.config.templates
            }
            if (item.config.extra.contas) {
              console.log('Alterando configurações do template', item.config.extra.contas)
              this.leilao.config.extra.contas = item.config.extra.contas
            }
          }
        }
      })
    },
    obrigarHabilitacaoLote (e) {
      this.$nextTick(() => {
        const v = this.leilao.extra.obrigarHabilitacaoLote
        if (v === true) {
          this.leilao.direitoPreferencia = true
        }
      })
    }
  },
  components: {
    LeilaoLocal,
    LeilaoImpostos,
    LeilaoInfo,
    Automacao,
    SlCheckbox,
    LayoutPaper,
    Multisite,
    WindowLoading,
    ERow,
    ECol,
    Imagem,
    ComitenteMultipleSelect,
    StatusSelect,
    DefaultAsyncSelect,
    TipoLeilaoSelect,
    // WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    // HelperInputBtn,
    // ErpBox,
    UEditor,
    // URadio
    ErpSelect,
    DatetimeInput,
    ErpCheckbox
  }
}
</script>
